import React, { useEffect, useState } from "react";
import Moment from "react-moment";
import ReactPaginate from "react-paginate";
import OnlineOrderCard from "./OnlineOrderCard";
import OnlineOrderPrint from "./OnlineOrderPrint";
import Clock from "react-live-clock";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ReportPrintOnline from "./ReportPrintOnline";

const ReportOnline = ({ smokeInfo, activePrint, silentPrint }) => {
  const [orderData, setOrderData] = useState([]);
  const [time, setTime] = useState(-1);
  const [now, setNow] = useState();
  const [year, setYear] = useState(null);
  const [active, setActive] = useState(-1);
  const [activeA, setActiveA] = useState(-1);
  const [selOrder, setSelOrder] = useState([]);
  const [analysis, setAnalysis] = useState(false);
  const [day1, setDay1] = useState(new Date());
  const yesterdayCOMP = new Date();

  yesterdayCOMP.setDate(yesterdayCOMP.getDate() - 1);
  const [day2, setDay2] = useState(yesterdayCOMP);

  const [comp1, setComp1] = useState([]);
  const [comp2, setComp2] = useState([]);
  const [cartID, setCartID] = useState([]);
  const [foodName, setFoodName] = useState([]);
  const [qty, setQty] = useState([]);
  const [foodSum, setFoodSum] = useState([]);
  const [condId, setCondId] = useState([]);
  const [condName, setCondName] = useState([]);
  const [condValue, setCondValue] = useState([]);

  const [total, setTotal] = useState(0);
  const [cashTotal, setCashTotal] = useState(0);
  const [cardTotal, setCardTotal] = useState(0);

  const [take, setTake] = useState(0);
  const [deli, setDeli] = useState(0);

  const [printTime, setPrintTime] = useState(null);

  useEffect(() => {
    const fetchToday = async () => {
      let url = "";
      if (time === -2) {
        url =
          "https://nannabiryani.co.uk/api/epos/orders/online/yesterday";
      } else if (time === -1) {
        url =
          "https://nannabiryani.co.uk/api/epos/orders/online/today";
      } else if (time === 0) {
        url =
          "https://nannabiryani.co.uk/api/epos/orders/online/week";
      } else if (time > 0 && time <= 12) {
        url = `https://nannabiryani.co.uk/api/epos/orders/online/month/${time}`;
      } else if (time === -3) {
        url =
          "https://nannabiryani.co.uk/api/epos/orders/online/analysis";
      } else {
        url = `https://nannabiryani.co.uk/api/epos/orders/online/year/${time}`;
      }

      const res = await fetch(url);
      const data = await res.json();
      return data;
    };

    const getToday = async () => {
      const orders = await fetchToday();
      setOrderData(orders);
      if (orders.length > 0) {
        setPrintTime(orders[0].created_at);
      }
      const total = Object.values(orders).reduce(
        (r, { total }) => r + total,
        0
      );

      const cashOrders = orders.filter((order) => order.payment === "Cash");
      const cash = Object.values(cashOrders).reduce(
        (r, { total }) => r + total,
        0
      );

      const cardOrders = orders.filter((order) => order.payment === "Payment");
      const card = Object.values(cardOrders).reduce(
        (r, { total }) => r + total,
        0
      );

      const takeOrders = orders.filter(
        (order) => order.orderType === "Collection"
      );

      const deliOrders = orders.filter(
        (order) => order.orderType === "Delivery"
      );

      setTotal(Math.round((total + Number.EPSILON) * 100) / 100);
      setCashTotal(Math.round((cash + Number.EPSILON) * 100) / 100);
      setCardTotal(Math.round((card + Number.EPSILON) * 100) / 100);

      setTake(takeOrders.length);
      setDeli(deliOrders.length);
    };

    getToday();
  }, [time]);

  useEffect(() => {
    const dataTime = new Date();
    setNow(dataTime);
    if (year === null) {
      setYear(dataTime.getFullYear());
    }
  }, [year]);

  const showDetails = async (id) => {
    setActive(id);
    const order = orderData.filter((data) => data.id === id);
    setSelOrder(order[0]);
    setFoodName(order[0].foodName.split(","));
    setQty(order[0].foodQty.split(","));
    setFoodSum(order[0].foodPrice.split(","));
    let arr = order[0].foodQty.split(",");
    let ids = Array.from(Array(arr.length).keys());
    setCartID(ids);

    if (order[0].cartConditions != null) {
      setCondName(order[0].cartConditions.split(","));
      setCondValue(order[0].conditionValues.split(","));

      let condArr = order[0].conditionValues.split(",");
      let condIds = Array.from(Array(condArr.length).keys());
      setCondId(condIds);
    }
  };

  //pagination//
  const [pageNumber, setPageNumber] = useState(0);
  const ordersPerPage = 20;
  const pagesVisited = pageNumber * ordersPerPage;
  const pageCount = Math.ceil(orderData.length / ordersPerPage);

  const displayOrders = orderData
    .slice(pagesVisited, pagesVisited + ordersPerPage)
    .map((order) => {
      return (
        <OnlineOrderCard
          order={order}
          key={order.id}
          showDetails={showDetails}
          active={active}
        ></OnlineOrderCard>
      );
    });

  const changePage = ({ selected }) => {
    setPageNumber(selected);
  };

  const compareData = () => {
    var date1 = day1.getDate().toString().padStart(2, "0");
    var month1 = (day1.getMonth() + 1).toString().padStart(2, "0");
    var year1 = day1.getFullYear();
    var comp1 = year1 + "-" + month1 + "-" + date1;

    var date2 = day2.getDate().toString().padStart(2, "0");
    var month2 = (day2.getMonth() + 1).toString().padStart(2, "0");
    var year2 = day2.getFullYear();
    var comp2 = year2 + "-" + month2 + "-" + date2;

    const day1Data = orderData.filter((data) => data.date === comp1);
    const day2Data = orderData.filter((data) => data.date === comp2);
    setComp1(day1Data);
    setComp2(day2Data);
  };

  return (
    <div>
      {!activePrint ? (
        <div>
          {active >= 0 ? (
            <div id="printThis" className="printContent">
              <OnlineOrderPrint
                smokeInfo={smokeInfo}
                selOrder={selOrder}
                cartID={cartID}
                foodName={foodName}
                qty={qty}
                foodPrice={foodSum}
                condId={condId}
                condName={condName}
                condValue={condValue}
              ></OnlineOrderPrint>
            </div>
          ) : (
            <div id="printThis" className="printContent">
              <ReportPrintOnline
                smokeInfo={smokeInfo}
                time={time}
                printTime={printTime}
                year={year}
                total={total}
                cashTotal={cashTotal}
                cardTotal={cardTotal}
                take={take}
                deli={deli}
              ></ReportPrintOnline>
            </div>
          )}
        </div>
      ) : null}
      <div className="grid grid-cols-10">
        <div className="col-span-7 mt-16 border-t">
          <div className="p-4 ml-1 bg-white rounded-lg">
            <div className="flex items-center justify-start gap-2 mb-1">
              <button
                onClick={() => {
                  setTime(-2);
                  setAnalysis(false);
                }}
                className={`px-3 py-3 font-bold ${
                  time === -2
                    ? `text-indigo-700 bg-indigo-200 border-t-2`
                    : `text-white bg-indigo-400 border-b-4`
                } border-indigo-700  border rounded-md`}
              >
                YesterDay
              </button>

              <button
                onClick={() => {
                  setTime(-1);
                  setAnalysis(false);
                }}
                className={`px-3 py-3 font-bold ${
                  time === -1
                    ? `text-purple-700 bg-purple-200 border-t-2`
                    : `text-white bg-purple-400 border-b-4`
                } border-purple-700  border rounded-md`}
              >
                Today
              </button>

              <button
                onClick={() => {
                  setTime(0);
                  setAnalysis(false);
                }}
                className={`px-3 py-3 font-bold ${
                  time === 0
                    ? `text-green-700 bg-green-200 border-t-2`
                    : `text-white bg-green-400 border-b-4`
                } border-green-700  border rounded-md`}
              >
                Week
              </button>

              <button
                onClick={() => {
                  setTime(now.getMonth() + 1);
                  setAnalysis(false);
                }}
                className={`px-3 py-3 font-bold ${
                  time > 0 && time <= 12
                    ? `text-blue-700 bg-blue-200 border-t-2`
                    : `text-white bg-blue-400 border-b-4`
                } border-blue-700  border rounded-md`}
              >
                Month
              </button>
              <div className="flex items-center">
                <button
                  onClick={() => {
                    setYear(year + 1);
                  }}
                  className={`px-3 py-3 font-bold  text-pink-700 bg-pink-400
              border-pink-700 border-b-4 border rounded-l-md`}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="w-6 h-6"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M5 15l7-7 7 7"
                    />
                  </svg>
                </button>

                <div className="w-16 px-3 py-3 font-bold text-pink-700 bg-pink-200 border-t border-b-4 border-pink-700">
                  {year}
                </div>

                <button
                  onClick={() => {
                    setYear(year - 1);
                  }}
                  className={`px-3 py-3 font-bold  text-pink-700 bg-pink-400
              border-pink-700 border-b-4 border `}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="w-6 h-6"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M19 9l-7 7-7-7"
                    />
                  </svg>
                </button>

                <button
                  onClick={() => {
                    setTime(year);
                    setAnalysis(false);
                  }}
                  className={`px-5 py-3 font-bold ${
                    time > 12
                      ? `text-pink-700 bg-pink-200 `
                      : `text-white bg-pink-400`
                  } border-pink-700 border-b-4 border rounded-r-md`}
                >
                  Year
                </button>
              </div>

              <button
                onClick={() => {
                  setTime(-3);
                  setAnalysis(true);
                }}
                className={`px-3 py-3 font-bold  ${
                  analysis
                    ? `text-yellow-700 bg-yellow-200`
                    : `bg-yellow-500 text-white border-b-4`
                }  border  border-yellow-700 rounded-md`}
              >
                Analysis
              </button>
            </div>
            <h4 className="text-lg font-bold text-left">
              {time === -2 ? "Yesterday" : null}
              {time === -1 ? "Today" : null}
              {time === 0 ? "This Week" : null}
              {time === 1 ? "January" : null}
              {time === 2 ? "February" : null}
              {time === 3 ? "march" : null}
              {time === 4 ? "April" : null}
              {time === 5 ? "May" : null}
              {time === 6 ? "June" : null}
              {time === 7 ? "July" : null}
              {time === 8 ? "August" : null}
              {time === 9 ? "September" : null}
              {time === 10 ? "October" : null}
              {time === 11 ? "November" : null}
              {time === 12 ? "December" : null}
              {time > 12 ? `Year - ${year}` : null}
            </h4>
            <div className={` ${analysis ? `reportAnalysis` : `reportORders`}`}>
              {analysis ? (
                <div>
                  <table className="w-full border table-auto">
                    <thead>
                      <tr>
                        <th>Date</th>
                        <th>Payment Type</th>
                        <th>Order Type</th>
                        <th>Orders</th>
                        <th>Total</th>
                      </tr>
                    </thead>

                    <tbody>
                      {orderData.map((data) => (
                        <tr
                          key={data.id}
                          className={`h-10 border-2 ${
                            activeA === data.id ? `bg-gray-100` : ``
                          }`}
                          onClick={() => setActiveA(data.id)}
                        >
                          <td className="border-r">
                            <h4 className="font-bold text-gray-600">
                              <Moment format="DD MMM YY" date={data.date} />
                            </h4>
                          </td>

                          <td className="p-1 border-r ">
                            <div className="flex items-center justify-between">
                              <p className="text-sm">Cash ({data.cashCount})</p>
                              <div className="px-1 font-semibold text-green-500">
                                £{parseFloat(data.cashTotal).toFixed(2)}
                              </div>
                            </div>
                            <div className="flex items-center justify-between">
                              <p className="text-sm">Card ({data.cardCount})</p>
                              <div className="px-1 font-semibold text-yellow-400">
                                £{parseFloat(data.cardTotal).toFixed(2)}
                              </div>
                            </div>
                          </td>

                          <td className="p-1 border-r">
                            <div className="flex items-center justify-between">
                              <p className="text-sm">
                                Collection ({data.takeCount})
                              </p>
                              <div className="px-1 font-semibold text-indigo-500">
                                £{parseFloat(data.takeTotal).toFixed(2)}
                              </div>
                            </div>

                            <div className="flex items-center justify-between">
                              <p className="text-sm">
                                Delivery ({data.deliCount})
                              </p>
                              <div className="px-1 font-semibold text-pink-500">
                                £{parseFloat(data.deliTotal).toFixed(2)}
                              </div>
                            </div>
                          </td>
                          <td className="p-1 border-r">
                            <p className="text-base font-bold text-green-400">
                              {data.orderCount}
                            </p>
                          </td>

                          <td className="p-1">
                            <p className="text-lg font-bold text-green-400">
                              £{parseFloat(data.total).toFixed(2)}
                            </p>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              ) : (
                <div className="grid grid-cols-5 gap-2">{displayOrders}</div>
              )}
            </div>
            {analysis ? null : (
              <ReactPaginate
                previousLabel={"Prev"}
                nextLabel={"Next"}
                pageCount={pageCount}
                onPageChange={changePage}
                containerClassName={
                  "bg-gray-100 rounded-md flex items-center justify-start p-4 shadow-md"
                }
                previousLinkClassName={
                  "border-indigo-500 border border-b-4 p-3 font-bold rounded-l-md"
                }
                nextLinkClassName={
                  "border-indigo-500 border border-b-4 p-3 font-bold rounded-r-md"
                }
                pageLinkClassName={
                  "border-indigo-500 border border-b-4 px-4 py-3 font-bold"
                }
                breakLinkClassName={
                  "border-indigo-500 border border-b-4 px-4 py-3 font-bold"
                }
                disabledClassName={"rounded-md text-gray-400"}
                activeLinkClassName={"bg-indigo-500 text-white"}
              />
            )}
          </div>
        </div>

        <div className="col-span-3 border-l">
          <div className="w-full h-screen p-1">
            <div className="w-full h-full bg-white rounded-md">
              <div className="flex justify-between border-b items-top">
                <h1 className="mb-1 text-xl font-bold text-left">
                  Sales Report - Online
                </h1>
                <Clock
                  format={"hh:mm:ssA"}
                  ticking={true}
                  timezone={"GB"}
                  className="text-base font-semibold"
                />
              </div>
              {analysis ? (
                <div>
                  <h4 className="mb-2 text-lg font-bold text-gray-600">
                    Compare
                  </h4>
                  <DatePicker
                    dateFormat="dd,MMM-yy"
                    selected={day1}
                    onChange={(date) => setDay1(date)}
                    className="px-2 py-3 font-semibold text-center text-indigo-500 bg-indigo-200 border border-b-4 border-indigo-400 rounded-lg"
                    placeholderText="Select a Day"
                    maxDate={new Date()}
                    withPortal
                  />
                  <h4 className="mb-2 text-base font-bold text-indigo-500">
                    to compare with
                  </h4>
                  <DatePicker
                    dateFormat="dd,MMM-yy"
                    selected={day2}
                    onChange={(date) => setDay2(date)}
                    className="px-2 py-3 font-semibold text-center text-indigo-500 bg-indigo-200 border border-b-4 border-indigo-400 rounded-lg"
                    placeholderText="This Day"
                    maxDate={new Date()}
                    withPortal
                  />

                  {day1 != null && day2 != null ? (
                    <button
                      onClick={() => {
                        compareData();
                      }}
                      className="px-8 py-3 mt-2 font-semibold text-center text-green-500 bg-green-200 border border-b-4 border-green-400 rounded-lg"
                    >
                      Compare
                    </button>
                  ) : (
                    <div>
                      <button className="px-8 py-3 mt-2 font-semibold text-center text-gray-500 bg-gray-200 border border-b-4 border-gray-400 rounded-lg">
                        Compare
                      </button>
                      <p className="text-sm">Select the dates to compare</p>
                    </div>
                  )}
                  {comp1.length > 0 && comp2.length > 0 ? (
                    <div
                      className={`rounded-lg shadow p-2 my-3 ${
                        comp1[0].total - comp2[0].total > 0
                          ? `bg-gradient-to-b from-green-400  to-indigo-500`
                          : `bg-gradient-to-b from-red-400  to-indigo-500`
                      }`}
                    >
                      <h4 className="text-xl font-bold text-white">
                        {" "}
                        {comp1[0].total - comp2[0].total > 0
                          ? `Income`
                          : "Loss"}
                      </h4>
                      <div className="flex items-center justify-center gap-2">
                        {comp1[0].total - comp2[0].total > 0 ? (
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="w-8 h-8 text-xl font-semibold text-green-100 animate-bounce"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth={2}
                              d="M5 11l7-7 7 7M5 19l7-7 7 7"
                            />
                          </svg>
                        ) : (
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="w-8 h-8 text-xl font-semibold text-red-300 animate-bounce"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth={2}
                              d="M19 13l-7 7-7-7m14-8l-7 7-7-7"
                            />
                          </svg>
                        )}
                        <h4 className="text-4xl font-bold text-white">
                          £
                          {parseFloat(comp1[0].total - comp2[0].total).toFixed(
                            2
                          )}
                        </h4>
                      </div>

                      <div className="p-2 my-1 text-left bg-white rounded-lg bg-opacity-20">
                        <p className="text-lg font-bold text-white border-b border-white">
                          <Moment format="DD MMM-YY" date={comp1[0].date} />
                        </p>

                        <p className="text-base font-semibold text-white">
                          cash: {comp1[0].cashCount}{" "}
                          <span className="float-right">
                            {" "}
                            £{parseFloat(comp1[0].cashTotal).toFixed(2)}
                          </span>
                        </p>

                        <p className="text-base font-semibold text-white">
                          Card: {comp1[0].cardCount}{" "}
                          <span className="float-right">
                            {" "}
                            £{parseFloat(comp1[0].cardTotal).toFixed(2)}
                          </span>
                        </p>

                        <p className="text-base font-semibold text-white">
                          Free: {comp1[0].freeCount}{" "}
                          <span className="float-right">
                            {" "}
                            £{parseFloat(comp1[0].freeTotal).toFixed(2)}
                          </span>
                        </p>

                        <p className="text-base font-semibold text-white">
                          Orders: {comp1[0].orderCount}{" "}
                          <span className="float-right">
                            {" "}
                            £{parseFloat(comp1[0].total).toFixed(2)}
                          </span>
                        </p>
                        <div className="flex flex-wrap items-center justify-between gap-2 mt-2 border-t border-white">
                          <p className="text-base font-semibold text-white">
                            Eat-In: {comp1[0].eatCount}
                          </p>
                          <p className="text-base font-semibold text-white">
                            Takeaway: {comp1[0].takeCount}
                          </p>
                          <p className="text-base font-semibold text-white">
                            Delivery: {comp1[0].deliCount}
                          </p>
                        </div>
                      </div>

                      <div className="p-2 my-1 text-left bg-white rounded-lg bg-opacity-20">
                        <p className="text-lg font-bold text-right text-white border-b border-white">
                          <Moment format="DD MMM-YY" date={comp2[0].date} />
                        </p>

                        <p className="text-base font-semibold text-white">
                          cash: {comp2[0].cashCount}{" "}
                          <span className="float-right">
                            {" "}
                            £{parseFloat(comp2[0].cashTotal).toFixed(2)}
                          </span>
                        </p>

                        <p className="text-base font-semibold text-white">
                          Card: {comp2[0].cardCount}{" "}
                          <span className="float-right">
                            {" "}
                            £{parseFloat(comp2[0].cardTotal).toFixed(2)}
                          </span>
                        </p>

                        <p className="text-base font-semibold text-white">
                          Free: {comp2[0].freeCount}{" "}
                          <span className="float-right">
                            {" "}
                            £{parseFloat(comp2[0].freeTotal).toFixed(2)}
                          </span>
                        </p>

                        <p className="text-base font-semibold text-white">
                          Orders: {comp1[0].orderCount}{" "}
                          <span className="float-right">
                            {" "}
                            £{parseFloat(comp2[0].total).toFixed(2)}
                          </span>
                        </p>
                        <div className="flex flex-wrap items-center justify-between gap-2 mt-2 border-t border-white">
                          <p className="text-base font-semibold text-white">
                            Eat-In: {comp2[0].eatCount}
                          </p>
                          <p className="text-base font-semibold text-white">
                            Takeaway: {comp2[0].takeCount}
                          </p>
                          <p className="text-base font-semibold text-white">
                            Delivery: {comp2[0].deliCount}
                          </p>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div className="flex flex-col items-center justify-center w-full p-2 my-2 bg-gray-200 rounded-lg">
                      <h4 className="text-2xl font-bold text-gray-400">
                        No Data
                      </h4>
                      <p className="text-sm text-gray-500">
                        One of the Date(s) had/have <br /> no orders to compare
                      </p>
                    </div>
                  )}
                </div>
              ) : (
                <div>
                  <div className="px-1 py-2 mb-2 bg-gray-200 rounded-lg">
                    <div className="w-full py-2 m-auto mt-2 mb-2 bg-white border-b-2 border-green-500 rounded-lg shadow-lg">
                      <h2 className="mx-auto text-2xl font-bold text-center text-green-500 ">
                        £{total}
                      </h2>
                      <h4 className="mx-auto text-lg font-bold text-center text-gray-400">
                        Total
                      </h4>
                    </div>

                    <div className="flex items-center justify-between gap-2 mb-2">
                      <div className="w-full py-2 m-auto mt-2 bg-white border-b-2 border-blue-500 rounded-lg shadow-lg">
                        <h2 className="mx-auto text-base font-bold text-center text-blue-500 ">
                          £{cashTotal}
                        </h2>
                        <h4 className="mx-auto text-sm font-bold text-center text-gray-400">
                          Cash
                        </h4>
                      </div>

                      <div className="w-full py-2 m-auto mt-2 bg-white border-b-2 border-yellow-500 rounded-lg shadow-lg ">
                        <h2 className="mx-auto text-base font-bold text-center text-yellow-500 ">
                          £{cardTotal}
                        </h2>
                        <h4 className="mx-auto text-sm font-bold text-center text-gray-400">
                          Card
                        </h4>
                      </div>
                    </div>

                    <div className="flex items-center justify-start gap-2 p-2 my-2">
                      <div className="w-20 h-20 p-2 bg-white border border-b-4 border-blue-500 rounded-lg shadow-lg">
                        <h4 className="text-sm font-semibold text-left text-gray-600">
                          Collection:
                        </h4>
                        <h2 className="text-xl font-bold text-right text-blue-500">
                          {take}
                        </h2>
                      </div>
                      <div className="w-20 h-20 p-2 bg-white border border-b-4 border-red-500 rounded-lg shadow-lg">
                        <h4 className="text-sm font-semibold text-left text-gray-600">
                          Delivery:
                        </h4>
                        <h2 className="text-xl font-bold text-right text-red-500">
                          {deli}
                        </h2>
                      </div>
                    </div>
                    <div className="my-2">
                      {!activePrint && orderData.length > 0 ? (
                        <button
                          onClick={() => silentPrint()}
                          className="flex items-center justify-center w-full gap-2 px-4 py-2 text-xl font-bold text-white bg-indigo-400 border-b-4 border-indigo-700 rounded-lg"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="w-6 h-6"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth={2}
                              d="M17 17h2a2 2 0 002-2v-4a2 2 0 00-2-2H5a2 2 0 00-2 2v4a2 2 0 002 2h2m2 4h6a2 2 0 002-2v-4a2 2 0 00-2-2H9a2 2 0 00-2 2v4a2 2 0 002 2zm8-12V5a2 2 0 00-2-2H9a2 2 0 00-2 2v4h10z"
                            />
                          </svg>{" "}
                          Print Record
                        </button>
                      ) : (
                        <button className="flex items-center justify-center w-full gap-2 px-4 py-2 text-xl font-bold text-gray-600 bg-gray-400 border-b-4 border-gray-700 rounded-lg">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="w-6 h-6"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth={2}
                              d="M17 17h2a2 2 0 002-2v-4a2 2 0 00-2-2H5a2 2 0 00-2 2v4a2 2 0 002 2h2m2 4h6a2 2 0 002-2v-4a2 2 0 00-2-2H9a2 2 0 00-2 2v4a2 2 0 002 2zm8-12V5a2 2 0 00-2-2H9a2 2 0 00-2 2v4h10z"
                            />
                          </svg>{" "}
                          Print Record
                        </button>
                      )}
                    </div>
                  </div>

                  {time > 0 && time <= 12 ? (
                    <div className="grid grid-cols-3 gap-2">
                      <button
                        onClick={() => setTime(1)}
                        className={`${
                          time === 1
                            ? `bg-blue-100 border-t-2 text-blue-700`
                            : `bg-blue-400 text-white border-b-4`
                        } border-blue-700 border rounded-md px-5 py-3 font-bold`}
                      >
                        Jan
                      </button>
                      <button
                        onClick={() => setTime(2)}
                        className={`${
                          time === 2
                            ? `bg-blue-100 border-t-2 text-blue-700`
                            : `bg-blue-400 text-white border-b-4`
                        } border-blue-700 border rounded-md px-5 py-3 font-bold`}
                      >
                        Feb
                      </button>
                      <button
                        onClick={() => setTime(3)}
                        className={`${
                          time === 3
                            ? `bg-blue-100 border-t-2 text-blue-700`
                            : `bg-blue-400 text-white border-b-4`
                        } border-blue-700 border rounded-md px-5 py-3 font-bold`}
                      >
                        Mar
                      </button>
                      <button
                        onClick={() => setTime(4)}
                        className={`${
                          time === 4
                            ? `bg-blue-100 border-t-2 text-blue-700`
                            : `bg-blue-400 text-white border-b-4`
                        } border-blue-700 border rounded-md px-5 py-3 font-bold`}
                      >
                        Apr
                      </button>
                      <button
                        onClick={() => setTime(5)}
                        className={`${
                          time === 5
                            ? `bg-blue-100 border-t-2 text-blue-700`
                            : `bg-blue-400 text-white border-b-4`
                        } border-blue-700 border rounded-md px-5 py-3 font-bold`}
                      >
                        May
                      </button>
                      <button
                        onClick={() => setTime(6)}
                        className={`${
                          time === 6
                            ? `bg-blue-100 border-t-2 text-blue-700`
                            : `bg-blue-400 text-white border-b-4`
                        } border-blue-700 border rounded-md px-5 py-3 font-bold`}
                      >
                        Jun
                      </button>
                      <button
                        onClick={() => setTime(7)}
                        className={`${
                          time === 7
                            ? `bg-blue-100 border-t-2 text-blue-700`
                            : `bg-blue-400 text-white border-b-4`
                        } border-blue-700 border rounded-md px-5 py-3 font-bold`}
                      >
                        Jul
                      </button>
                      <button
                        onClick={() => setTime(8)}
                        className={`${
                          time === 8
                            ? `bg-blue-100 border-t-2 text-blue-700`
                            : `bg-blue-400 text-white border-b-4`
                        } border-blue-700 border rounded-md px-5 py-3 font-bold`}
                      >
                        Aug
                      </button>
                      <button
                        onClick={() => setTime(9)}
                        className={`${
                          time === 9
                            ? `bg-blue-100 border-t-2 text-blue-700`
                            : `bg-blue-400 text-white border-b-4`
                        } border-blue-700 border rounded-md px-5 py-3 font-bold`}
                      >
                        Sep
                      </button>
                      <button
                        onClick={() => setTime(10)}
                        className={`${
                          time === 10
                            ? `bg-blue-100 border-t-2 text-blue-700`
                            : `bg-blue-400 text-white border-b-4`
                        } border-blue-700 border rounded-md px-5 py-3 font-bold`}
                      >
                        Oct
                      </button>
                      <button
                        onClick={() => setTime(11)}
                        className={`${
                          time === 11
                            ? `bg-blue-100 border-t-2 text-blue-700`
                            : `bg-blue-400 text-white border-b-4`
                        } border-blue-700 border rounded-md px-5 py-3 font-bold`}
                      >
                        Nov
                      </button>
                      <button
                        onClick={() => setTime(12)}
                        className={`${
                          time === 12
                            ? `bg-blue-100 border-t-2 text-blue-700`
                            : `bg-blue-400 text-white border-b-4`
                        } border-blue-700 border rounded-md px-5 py-3 font-bold`}
                      >
                        Dec
                      </button>
                    </div>
                  ) : null}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      {active > 0 ? (
        <div className="absolute top-0 left-0 z-50 flex items-center justify-center w-screen h-screen bg-white bg-opacity-40 backdrop-filter backdrop-blur-md">
          <div className="p-10 bg-white rounded-lg shadow-2xl orderDetailsHover">
            <div className="flex items-center justify-between pb-2 mb-2 border-b">
              <h1 className="mb-1 text-xl font-bold text-left">
                Order Details
              </h1>
              <button
                onClick={() => setActive(-1)}
                className="p-2 font-bold text-white bg-red-500 border-b-4 border-red-700 rounded-lg shadow-lg"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="w-6 h-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </button>
            </div>
            <div className="grid grid-cols-2 gap-2">
              <div>
                <div className="p-2 mt-1 mb-2 border border-gray-200 rounded-md shadow-md">
                  <div className="flex items-center justify-between border-b border-gray-400">
                    <div>
                      <h4 className="text-lg font-bold text-left text-yellow-500">
                        Order [#{selOrder.id}]
                      </h4>
                      {selOrder.refundId != null ? (
                        <h4 className="text-sm font-bold text-left text-red-500">
                          Refunded
                        </h4>
                      ) : null}
                      <p className="text-left">
                        <Moment format="DD/MM/YYYY">
                          {selOrder.created_at}
                        </Moment>
                      </p>
                    </div>

                    <div>
                      <p className="text-right">
                        Order-Type:
                        <span className="ml-1 font-semibold text-blue-500">
                          {selOrder.orderType}
                        </span>
                      </p>
                      <p className="text-right">
                        Payment:
                        <span className="ml-1 font-semibold text-green-500">
                          {selOrder.payment}
                        </span>
                      </p>
                    </div>
                  </div>

                  <div>
                    <div className="flex items-start justify-between gap-2">
                      <div>
                        <p className="text-sm text-left">
                          Customer:
                          <span className="ml-1 font-semibold ">
                            {selOrder.userFname} {selOrder.userLname}
                          </span>
                        </p>
                        <p className="text-sm text-left">
                          Mobile/Land:
                          <span className="ml-1 font-semibold text-red-500">
                            {selOrder.userPhone}
                          </span>
                        </p>
                        <p className="text-sm text-left">
                          Email:
                          <span className="ml-1 font-semibold ">
                            {selOrder.userEmail}
                          </span>
                        </p>
                      </div>

                      {selOrder.orderType === "Delivery" ? (
                        <p className="text-sm font-semibold text-left">
                          Delivery Address:
                          <span className="ml-1 font-normal">
                            {selOrder.deliaddress1} <br />
                            {selOrder.deliaddress2}, {selOrder.delicity},{" "}
                            {selOrder.deliregion}
                            <span className="font-semibold">
                              {" "}
                              {selOrder.delipostcode}
                            </span>
                          </span>
                        </p>
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>

              <div>
                <div className="p-2 mb-2 border border-gray-200 rounded-md shadow-lg">
                  <div className="grid items-center grid-cols-6 gap-2 py-1 border-b-2 border-gray-300">
                    <p className="font-semibold text-left">Qty</p>
                    <p className="col-span-4 font-semibold text-left">
                      Item name
                    </p>
                    <p className="font-semibold text-center ">Price</p>
                  </div>
                  <div className="orderCart-wrapper">
                    {cartID.map((cart, index) => (
                      <div
                        key={index}
                        className="grid items-center grid-cols-6 gap-2 py-1 border-b border-gray-300 border-dashed"
                      >
                        <p className="text-left">{qty[index]}x </p>
                        <p className="col-span-4 text-left">
                          {foodName[index]}
                        </p>
                        <p className="text-center">£{foodSum[index]}</p>
                      </div>
                    ))}
                  </div>
                  <div className="flex items-center justify-between">
                    <p className="font-semibold">Subtotal</p>
                    <p className="font-semibold">
                      £{parseFloat(selOrder.subTotal).toFixed(2)}
                    </p>
                  </div>
                  {selOrder.cartConditions != null ? (
                    <div>
                      {condId.map((cart, index) => (
                        <div key={index}>
                          <div className="flex items-center justify-between text-yellow-600">
                            <p className="font-semibold">{condName[index]}</p>
                            <p className="font-semibold">{condValue[index]}</p>
                          </div>
                        </div>
                      ))}
                    </div>
                  ) : null}
                  <div className="flex items-center justify-between">
                    <p className="text-xl font-bold text-green-400">Total</p>
                    <p className="text-xl font-bold text-green-400">
                      £{parseFloat(selOrder.total).toFixed(2)}
                    </p>
                  </div>
                </div>
                {!activePrint ? (
                  <button
                    onClick={() => silentPrint()}
                    className="flex items-center justify-center w-full gap-2 px-4 py-3 mx-2 text-xl font-bold text-white bg-blue-400 border-b-4 border-blue-700 rounded-lg"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="w-6 h-6"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M17 17h2a2 2 0 002-2v-4a2 2 0 00-2-2H5a2 2 0 00-2 2v4a2 2 0 002 2h2m2 4h6a2 2 0 002-2v-4a2 2 0 00-2-2H9a2 2 0 00-2 2v4a2 2 0 002 2zm8-12V5a2 2 0 00-2-2H9a2 2 0 00-2 2v4h10z"
                      />
                    </svg>{" "}
                    Re-Print
                  </button>
                ) : (
                  <button className="flex items-center justify-center w-full gap-2 px-4 py-3 mx-2 text-xl font-bold text-gray-600 bg-gray-400 border-b-4 border-gray-700 rounded-lg">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="w-6 h-6"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M17 17h2a2 2 0 002-2v-4a2 2 0 00-2-2H5a2 2 0 00-2 2v4a2 2 0 002 2h2m2 4h6a2 2 0 002-2v-4a2 2 0 00-2-2H9a2 2 0 00-2 2v4a2 2 0 002 2zm8-12V5a2 2 0 00-2-2H9a2 2 0 00-2 2v4h10z"
                      />
                    </svg>{" "}
                    Re-Print
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default ReportOnline;
